import * as React from 'react';
import { HeroBanner } from '../Components/Common/HeroBanner';
import { Paragraph } from '../Components/Common/Styles/Typography/Paragraph';
import { ContentContainer } from '../Components/Common/ContentContainer';
import styled from 'styled-components';
import { Flexed } from '../Components/Common/Styles/globalStyles';
import { Loading } from '../Components/Common/Loading';
import { parse } from 'node-html-parser';

interface PlaceholderPageProps {
    pageContext: {
        pageData: GatsbyTypes.WpGql_Page;
    }
}

type Header = {
    text: string;
}

const Wrapper = styled.section`
    padding: 16px;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        padding: 64px 0;

        article:last-of-type {
            width: calc(100% - 175px);
            flex: 1;
        }
    }
`

const Sidebar = styled.article`
    @media (min-width: ${props => props.theme.screensizes.medium}) {
        max-width: 175px;
        margin-right: 48px;
        padding-right: 0 !important;
    }

    h4 {
        font-size: 20px;
        line-height: 24px;
        font-family: var(--dinNarrow-font);
        text-transform: uppercase;
    }

    a {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 16px;
        font-weight: 200;
    }
`

const PlaceholderPage: React.FC<PlaceholderPageProps> = (props) => {
    const [sidebarHeaders, setSidebarHeaders] = React.useState<Header[]>([])
    
    const mapSidebarContent = async () => {
        if (!props.pageContext?.pageData?.content?.length) return;

        const pageContentDocument = parse(props.pageContext?.pageData?.content);
        const pageHeaders = Array.from(pageContentDocument.querySelectorAll('h3'));
        const headers: Header[] = pageHeaders.map(header => ({ text: header.innerText }));
        setSidebarHeaders(headers);
    };

    if (!sidebarHeaders.length) {
        mapSidebarContent();
        return <Loading/>
    }

    return (
        <>
            <HeroBanner pageSlug={ props.pageContext?.pageData?.slug } />
            <Wrapper>
                <ContentContainer>
                    <Flexed>
                        <Sidebar>
                            <h4>Contents</h4>
                            {
                                sidebarHeaders.map((header, index) => (
                                    <a key={ index }>{ header.text }</a>
                                ))
                            }
                        </Sidebar>
                        <Paragraph.Block text={ props.pageContext?.pageData?.content || '' }/>
                    </Flexed>
                </ContentContainer>
            </Wrapper>
        </>
    );
};

export default PlaceholderPage;